@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.product-table {

  max-width: 100vw;
  width: 1664px;
  overflow: auto;

  & > .product-table-table {

    width: 900px;
    border-collapse: collapse;
    margin: 0 auto;
    max-width: 95vw;

    & > .product-table-table-thead {

      & > .product-table-table-thead-row {

        & > .product-table-table-thead-item {

          text-align: left;
          padding-left: 5px;
          border-bottom: 1px solid var(--table-border);
          border-right: 1px solid var(--table-border);
          border-bottom: 1px solid var(--table-border);
          border-right: 1px solid var(--table-border);
          width: 329px;
          height: 33px;

          &:nth-last-child(1) {
            border-right: none;
          }

        }

      }

    }

    & > .product-table-table-tbody {

      & > .product-table-table-tbody-row {

        &:nth-last-child(1) {
          border-right: none;
          background-color: gold;
        }

      }

    }

  }

  &.product-table-dark {
    width: 1350px;
  }

}
