@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.main-layout {

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  background: var(--background);
  transition: 0.7s;
  min-height: 100vh;

  .main-layout-header {
    z-index: 1000;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-content: center;
    justify-content: center;
  }

  & > .main-layout-content {

    box-sizing: border-box;
    overflow: hidden;
    width: 100vw;
    min-width: 0;
    min-height: 100vh;
    max-width: 100vw;
    //max-height: 100vh;
    padding: 0 0 10rem 0;

    font-family: $p-font;
    color: var(--text-font-color);
    font-size: $p-font-size;
    line-height: $p-font-line-height;
    flex-grow: 1;

    h2 {
      max-width: 100vw;
      font-family: $h2-font;
      color: var(--text-font-color);
      font-size: $h2-font-size;
      font-weight: $font-weight-800;
      line-height: $h2-font-line-height;

      word-break: break-word;
      overflow-wrap: break-word;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      hyphens: auto;
    }

    h3 {
      max-width: 100vw;
      font-family: $h3-font;
      //color: var(--text-font-color);
      font-size: $h3-font-size;
      font-weight: $font-weight-800;
      line-height: $h3-font-line-height;

      word-break: break-word;
      overflow-wrap: break-word;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      hyphens: auto;
    }

  }

  & > .main-layout-footer {
    flex-shrink: 0;
  }

  .hg-card-label {
    font-family: $h2-font;
    color: var(--text-font-color);
    font-weight: $font-weight-800;
    line-height: $h2-font-line-height;
  }

  .hg-table {

    .hg-table-header-column {
      font-family: $h2-font;
      color: var(--text-font-color);
      font-weight: $font-weight-800;
      line-height: $h2-font-line-height;
    }

    .hg-table-row {

      & > .hg-table-column {
        border-radius: 0;
        border-top: 1px solid $table-border-color;
        &:first-child {
          border-left: 1px solid $table-border-color;
        }
        &:last-child {
          border-right: 1px solid $table-border-color;
        }
      }

      &:first-child {
        & > .hg-table-column {
          &:first-child {
            border-radius: 7px 0 0 0;
          }
          &:last-child {
            border-radius: 0 7px 0 0;
          }
        }
      }

      &:last-child {
        & > .hg-table-column {

          border-bottom: 1px solid $table-border-color;

          &:first-child {
            border-radius: 0 0 0 7px;
          }

          &:last-child {
            border-radius: 0 0 7px 0;
          }

        }
      }

      &:first-child:last-child {
        & > .hg-table-column {
          &:first-child {
            border-radius: 7px 0 0 7px;
          }
          &:last-child {
            border-radius: 0 7px 7px 0;
          }
        }
      }

    }

  }

  .hg-card {
    background-color: $white;
  }

  .hg-button {

    color: $white;
    background-color: $blue;
    text-align: center;
    font-size: $button-font-size;
    font-family: $button-font;
    line-height: $button-font-line-height;
    border-radius: 21px;
    border: none;

    &.hg-button-primary {
      color: $white;
      background-color: $blue;
    }

    &.hg-button-secondary {
      box-sizing: border-box;
      font-size: $button-font-size;
      font-family: $button-font;
      border-radius: 21px;
      background-color: transparent;
      color: var(--text-font-color);
      border: 2px solid var(--text-font-color);
      text-align: center;
      width: 156px;
      height: 41px;
      text-decoration: none;
      padding: 8px 16px;
    }

    &.hg-button-disabled {
      background-color: $grey;
    }

  }

}
