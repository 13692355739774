@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.nor-faq {

  box-sizing: border-box;
  width: 100%;
  max-width: 95vw;
  font-size: 14px;
  margin: 4em 0 4em 0;

  & > .nor-faq-container {

    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    //align-items: center;
    //justify-items: center;
    //align-content: center;
    //justify-content: center;
    flex-wrap: wrap;
    margin: 4em 0 0 0;

    & > .nor-faq-header {

      box-sizing: border-box;
      //flex-shrink: 1;
      //max-width: 30%;
      //width: 100%;
      margin: 2em 0 0 auto;
      overflow: hidden;

      & > .nor-faq-title {
        box-sizing: border-box;
        font-size: 25px;
        margin: 0 0 0 0;
        width: 100%;
        //text-align: left;
      }

      & > .nor-faq-description {
        box-sizing: border-box;
        font-size: 22px;
        margin: 2em 0 0 0;
      }

    }

    & > .nor-faq-content {

      box-sizing: border-box;
      flex-grow: 1;
      max-width: 30em;
      width: 100%;
      margin: 2em auto 0 2em;

      & > .nor-faq-content-item {

        margin: 0 0 3em 0;

        & > .nor-faq-content-item-title {

          color: #1689D0;
          cursor: pointer;
          font-weight: bold;
          box-sizing: border-box;
          font-size: 14px;
          margin: 0 0 1em 0;

          & > .nor-faq-content-item-title-caret {
            margin-left: 1em;
          }

        }

        & > .nor-faq-content-item-content {
          box-sizing: border-box;
          font-size: 14px;
          margin: 0;
        }

        &.nor-faq-content-item-selected {

          & > .nor-faq-content-item-title {
            color: #1689D0;
          }

        }

      }

    }

  }

  &.nor-faq-dark {

    & > .nor-faq-container {
      & > .nor-faq-content {
        & > .nor-faq-content-item {

          & > .nor-faq-content-item-title {
            color: #86beff;
          }

          &.nor-faq-content-item-selected {

            & > .nor-faq-content-item-title {
              color: #86beff;
            }

          }

        }
      }
    }

  }

}
