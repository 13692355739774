@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.atlas-extra-product-view {

  width: 100%;
  transition: $transition_main;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;

  & > .atlas-extra-product-view-article {
    background-color: var(--light-blue);
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;

    &.atlas-extra-product-view-odd-article {
      background-color: var(--background);
    }

  }

  //.shell-view-blue {
  //  width: 156px;
  //  height: 41px;
  //  margin-right: 24px;
  //}
  //
  //.shell-view-white {
  //  background-color: var(--button-white-bgcolor) !important;
  //  color: var(--text-font-color) !important;
  //  border: 2px solid var(--text-font-color) !important;
  //  text-align: center;
  //  width: 183px;
  //  height: 41px;
  //}
  //

}
