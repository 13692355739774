@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.my-new-client-article {

  box-sizing: border-box;
  margin: 0;
  padding-top: 62.13px;
  //padding-left: 57.53px;

  & > .my-new-client-article-form {

    box-sizing: border-box;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;

    & > h3 {
      text-align: center;
    }

    & > .my-new-client-article-fieldset {

      box-sizing: border-box;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-items: center;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;

      & > .field {
        flex-grow: 1;
      }

    }

    & > .my-new-client-article-buttons {

      box-sizing: border-box;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-items: center;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;

      & > .hg-button {
        margin: 0.5em;
      }

    }

  }

}
