.my-web-hotel-inventory-data-form {

  & > .my-web-hotel-inventory-data-form-form {

    & > .my-web-hotel-inventory-data-form-form-buttons {

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-items: center;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;

      & > .hg-button {
        min-width: 10em;
        margin: 0 0 0 1em;

        &:first-child {
          margin: 0;
        }
      }

    }


  }


}
