.hg-table-row {

  box-sizing: border-box;
  padding: 0;
  margin: 0;

  &.hg-table-row-clickable {
    cursor: pointer;
  }

}
