@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.virtual-server-intro-view {

  color: $dark-font-color;
  background-color: var(--background-darker);
  font-family: $table-title-2-font;
  font-size: $table-title-2-size;
  line-height: $table-title-2-line-height;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0 0 79.5px 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;

  & > .virtual-server-intro-view-article {
    overflow: auto;
    width: 1920px;
    max-width: 100vw;
    padding: 0;
    margin: 0 auto;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;

    & > .virtual-server-intro-view-article-header {
      h1 {
        padding: 0 0.5em;
      }
      p {
        padding: 0 0.5em;
      }
    }

  }

  & > h3 {
    margin-top: 4em;
  }

}
