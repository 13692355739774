@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.card-item {

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  transition: $transition_main;
  text-align: center;
  padding-bottom: 17.5px;

  & > .card-item-icon {
    > svg {
      width: 40.35px;

      > .cls-1 {
        fill: var(--text-font-color);
      }
    }
  }

  & > .card-item-title {
    font-family: $h5-font;
    font-size: $h5-font-size;
    line-height: $h5-font-line-height;
    //color: var(--text-font-color);
    padding-top: 12.5px;
    padding-bottom: 7px;
    width: 300px;
    height: 35px;
  }

  & > .card-item-description {
    //padding-bottom: 17.5px;
    text-align: center;
    //color: var(--text-font-color);
    font-family: $p-newsletter-font;
    font-size: $description-font-size;
    line-height: $p-font-line-height;
  }

  & > .card-item-buttons {

    box-sizing: border-box;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    & > .card-item-button {
      margin-top: 31px;
      min-width: 156px;
      min-height: 41px;
      //margin-bottom: 86.5px;
      margin-bottom: 31px;
    }

  }

  &.card-item-sold-out {
    color: #999;
    background-color: #f8f8f8;
    opacity: 0.6;
    border-radius: 8px;
  }

}
