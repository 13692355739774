@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.my-order-confirm-selected-client-article {

  box-sizing: border-box;
  margin: 0;
  padding-top: 62.13px;

  & > .my-order-confirm-selected-client-article-form {

    box-sizing: border-box;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;

    & > h3 {
      text-align: center;
    }

    & > p {
      text-align: center;
    }

    & > .my-order-confirm-selected-client-article-details {

      width: 750px;
      max-width: 90vw;
      box-sizing: border-box;
      padding: 0;
      margin: 0 0 2em 0;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-items: center;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;

      strong {
        font-family: $h2-font;
        color: var(--text-font-color);
        font-weight: $font-weight-800;
        line-height: $h2-font-line-height;
      }

      & > .my-order-confirm-selected-client-article-details-field {
        box-sizing: border-box;
        padding: 1em 2em;
        margin: 1em 5px;
        background-color: $white;
        border-radius: 12px;
        flex: 1 0 auto;
        line-height: 2em;

        overflow: hidden;
        min-width: 100px;
        max-width: 90vw;

      }

    }

    & > .my-order-confirm-selected-client-article-fieldset {

      width: 450px;
      max-width: 90vw;
      box-sizing: border-box;
      padding: 0;
      margin: 0 0 2em 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-items: center;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;

      & > .my-order-confirm-selected-client-article-radio-label {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        flex: 1 0 auto;
        line-height: 2em;
      }

    }

    & > .my-order-confirm-selected-client-article-buttons {

      box-sizing: border-box;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-items: center;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;

      & > .button {
        margin: 0 0.5em;
      }

    }

  }

}
