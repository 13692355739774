@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.my-invoice-list-view {

  box-sizing: border-box;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;

  & > h3 {
    text-align: center;
  }

  & > p {
    text-align: center;
  }

  & > .my-invoice-list-view-details {

    .hg-card {
      padding: 4px 6px;
      margin: 12px 0;
      overflow-x: auto;
    }

  }

}
