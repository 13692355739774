@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.cookies-article {

  strong {
    line-height: 2;
    font-size: 18px;
    font-family: $nav-font-family-bold;
  }

  & > .cookies-article-header {
    margin: 32px 0;
  }

  & > .cookies-article-settings {
    background-color: var(--light-blue);
  }

  & > .cookies-article-article {
    margin: 12px 0;
  }

  & > .cookies-article-provider {
    margin: 15px 0;
    padding: 12px 24px;
    background-color: var(--light-blue);

    & > h3 {
      margin: 15px 0;
    }

  }

}
