.legal-info {
  box-sizing: border-box;
  padding: 0;
  text-align: left;
  align-content: center;
  justify-content: center;

  & > .legal-info-copyright {
    margin-bottom: 0.5em;
  }

}
