@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.app-footer {

  box-sizing: border-box;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
  max-width: 100vw;
  //max-height: 100vh;

  margin: 0;
  height: auto;
  background-color: $background;
  font-size: 14px;
  line-height: 2;
  font-family: $footer-font-family;
  color: $footer-font-color;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  justify-content: center;

  a,
  a:visited,
  a:hover,
  a:active,
  .App-link {
    color: $footer-font-color;
    text-decoration: none;
  }

  a.activated {
    color: $blue;
  }

  & > .app-footer-content {

    width: 100%;
    max-width: 1920px;
    padding-top: 62.13px;
    padding-left: 57.53px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-evenly;
    justify-items: center;

    & > .app-footer-main-row {

      width: 100%;
      padding-right: 18.5%; //1920 355 reunasta
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;

      & > .app-footer-logo {

        a.app-logo {

          display: block;
          flex-shrink: 0;
          padding: 0;
          margin: 0;
          box-sizing: border-box;
          line-height: 0;
          align-content: center;

          & > svg {
            width: 190px;
            height: auto;
            fill: $footer-logo;
          }

        }
      }

      a,
      a:visited,
      a:hover,
      a:active,
      .App-link {
        color: $footer-font-color;
        text-decoration: none;
      }

      a.activated {
        color: $blue;
      }

      & > .app-footer-item {
        padding: 0 0.1em;

        &.app-footer-customer-service {
          max-width: 179px;

          .footer-title {
            line-height: 2;
            font-size: 14px;
            color: $footer-font-color;
            font-family: $nav-font-family-bold;
          }

          .space {
            padding-left: 5px;
          }

          .footer-2-line {
            padding-bottom: 32px;
            color: $footer-font-color;
          }
        }

        &.app-footer-legal {
          max-width: 179px;

          .footer-title {
            font-size: 14px;
            line-height: 2;
            color: $footer-font-color;
            font-family: $nav-font-family-bold;
          }

          a {
            font-size: 14px;
            line-height: 2;
            color: $footer-font-color;
            font-family: $nav-font-family;
          }
        }

        &.app-footer-links {
          text-align: left;
          font-size: 14px;
          flex-direction: column;
          max-width: 179px;

          .footer-title {
            font-size: 14px;
            line-height: 2;
            color: $footer-font-color;
            font-family: $nav-font-family-bold;
          }

          & > .app-footer-some {
            margin: 2em 0 2em 0;
            flex-shrink: 1;
          }

        }
      }

    }

    & > .app-footer-secondary-row {

      padding: 0 0 49px 0;
      margin-bottom: 0;
      display: flex;
      flex-direction: row;
      width: 100%;
      box-sizing: border-box;
      font-family: "redhat";
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0;
      color: $footer-font-color;
      opacity: 1;

      align-items: flex-end;
      justify-items: center;
      align-content: center;
      justify-content: stretch;
      flex-wrap: wrap;

      & > .app-footer-copyright {
        margin: 0 2em 2em 0;
        flex-shrink: 1;
      }

      & > .app-footer-payment-options {
        margin: 0 2em 2em 0;
        flex-grow: 1;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-items: center;
        align-content: center;
        justify-content: center;
        flex-wrap: wrap;

        & > .app-footer-payment-options-list {
          max-width: 800px;
        }

      }

    }

  }

  .footer-nav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 130px;
    text-align: left;

    > a.nav-link {
      width: 100%;
      font-size: 14px;
      line-height: 2;
      font-family: $footer-font-family;
      color: $footer-font-color;
    }

    & > a,
    & > a:visited,
    & > a:active,
    & > a:hover {
      color: $footer-font-color;
    }

    a.activated {
      color: $blue;
      text-decoration: none;
      //transition: all 250ms ease-in-out;
    }
  }

}

// IPads (portraid and landscape) 768-1024 1024-1223 lisätty myös tähän
@media only screen and (min-width: 768px) and (max-width: 1223px) {
  .app-footer {
    .app-footer-content {
      .app-footer-main-row {
        padding-right: 2.5em;
      }
    }
  }
}

// mobiili
@media only screen and (max-width: 767px) {
  .app-footer {
    .app-footer-content {
      .app-footer-main-row {
        justify-content: left;

        & > .app-footer-logo {
          padding-bottom: 2em;
        }

        .app-footer-item {
          width: 100%;
          padding: 0 0em;
          margin-bottom: 0;
          height: auto;
          padding-top: 1em;
          padding-bottom: 1em;

          /*.app-footer-nav {

          }*/
          &.app-footer-customer-service {
            max-width: 100%;

            .footer-2-line {
              padding-bottom: 24px;
            }
          }

          &.app-footer-some {
            max-width: 100%;
          }

          &.app-footer-legal {
            max-width: 100%;
          }

          &.app-footer-links {
            max-width: 100%;
          }
        }
      }

      .app-footer-secondary-row {
        padding-top: 2em;
        flex-wrap: wrap;
        height: auto;
      }
    }
  }

}
