@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.customer-service-view {

  width: 100%;
  transition: $transition_main;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;

  article.customer-service-view {
    //background-color: #e7edf2;
    background-color: var(--background);
    width: 100%;
    display: flex;

    align-items: center;
    align-content: center;
    justify-content: center;

  }

  .customer-service-view-info {

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    align-content: center;
    justify-content: center;
    width: 100%;

    .customer-service-view-info-item {
      margin: 1em;
      width: 100%;
      max-width: 350px;
    }

  }

  .customer-service-view-footer {
    padding: 3em 2em 0;
  }

}
