@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.domain-sale-list-view {

  box-sizing: border-box;
  background-color: var(--background);
  width: 100vw;
  margin: 0;
  padding: 8.7% 0 9.8% 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  min-height: 100vh;

  & > .domain-sale-list-view-article {

    width: 100vw;
    max-width: 1920px;
    padding: 0;
    margin: 0 auto;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;

    & > .domain-sale-list-view-article-header {

      padding-bottom: 74px;
      padding-top: 148px;

      box-sizing: border-box;
      //padding: 0;
      //margin: 2em 0 3em 0;
      align-content: center;
      text-align: center;

      & > .article-header-title {
        box-sizing: border-box;
        margin: 0 auto 2em auto;
        padding: 0;
        font-family: $h2-font;
        color: var(--text-font-color);
        font-size: $h2-font-size;
        font-weight: $font-weight-800;
        line-height: $h2-font-line-height;
      }

      p {
        box-sizing: border-box;
        max-width: 589px;
        max-height: 52px;
        font-family: $p-font;
        color: var(--text-font-color);
        font-size: $p-font-size;
        line-height: $p-font-line-height;
        padding: 1em;
      }

    }

    & > .domain-sale-list-view-article-content {

      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin: 0;
      width: 100%;

      & > .domain-sale-list-view-article-content-description {

        box-sizing: border-box;
        margin: 6em 0 2em 0;
        max-width: 589px;
        font-family: $p-font;
        color: var(--text-font-color);
        font-size: $p-font-size;
        line-height: $p-font-line-height;

        p {
          padding: 0 1em;
        }

        //// IPads (portraid and landscape) 768-1024 1024-1223 lisätty myös tähän
        //@media only screen and (min-width: 768px) and (max-width: 1223px) {
        //  & {
        //    max-width: 70%;
        //  }
        //}

        //// mobiili
        //@media only screen and (max-width: 767px) {
        //  & {
        //    max-width: 70%;
        //  }
        //}

      }

      & > .domain-sale-list-view-article-content-results {
        position: relative;

        & > .domain-sale-list-view-article-content-results-product {

          & > .card-item {
            border-radius: 7px;
            padding: 1em 1em;
            margin: 0;
            background-color: #fff;

            & > .price-type-select.domain-sale-list-view-select-price {
              margin: 0 2em;
            }

          }

        }

        & > .domain-sale-list-view-article-content-results-loader {
          position: absolute;
          top: 3em;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: var(--background);
        }

      }

    }

  }

}