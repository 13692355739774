.my-invoice-view {

  box-sizing: border-box;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;

  & > h3 {
    text-align: center;
  }

  & > p {
    text-align: center;
  }

  & > .my-invoice-view-details {

    & > h3 {
      text-align: center;
    }

    & > p {
      text-align: center;
      max-width: 600px;
      margin: 0 auto 1em auto;
    }

    & > .my-invoice-view-payment-terms {
      text-align: center;
    }

    & > .my-invoice-view-bank-details {
      margin-bottom: 0;
    }

    & > .my-invoice-view-payment-options {

      & > .hg-card {

        box-sizing: border-box;
        margin: 12px;
        padding: 4px 6px;
        max-width: 50%;

        & > .hg-card-content {

          //display: flex;
          //flex-direction: column;
          //align-items: center;
          //justify-items: center;
          //align-content: center;
          //justify-content: center;
          //flex-wrap: wrap;

          & > .my-invoice-view-payment-provider {
            width: auto;
            height: 1.5em;
            margin: 0.25em 0.5em;
          }
        }
      }
    }

    & > .my-invoice-view-provider-options {

      & > .hg-card {

        max-width: 50%;

        & > .hg-card-content {

          //display: flex;
          //flex-direction: row;
          //align-items: center;
          //justify-items: center;
          //align-content: center;
          //justify-content: center;
          //flex-wrap: wrap;

          & > form {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-items: center;
            align-content: center;
            justify-content: center;
            flex-wrap: wrap;

            & > .my-invoice-view-payment-provider {
              width: auto;
              height: 3em;
              margin: 0.25em 0.5em;
            }

          }

        }
      }
    }

    & > .hg-card {
      overflow-x: auto;
    }

  }

}
