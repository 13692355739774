.hg-card-grid {

  max-width: 90vw;
  box-sizing: border-box;
  padding: 0;
  margin: 0 0 2em 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;

  & > .hg-card {
    box-sizing: border-box;
    padding: 1em 2em;
    margin: 1em 5px;
    border-radius: 12px;
    flex: 1 0 auto;
    line-height: 2em;
    overflow: hidden;
    min-width: 100px;
    max-width: 90vw;
  }

}
