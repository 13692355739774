.all-features {

  max-width: 1664px;
  width: 87%;
  padding-top: 31px;

  > hr {
    border: 1px solid var(--table-border);
  }

  .button.all-features-white {
    //background-color: var(--newsletter-bgcolor);
    background-color: transparent;
    color: var(--blue);
    border: 2px solid var(--blue);
    text-align: center;
    width: 268px;
    height: 41px;
    margin-bottom: 31px;
    border-radius: 21px;

    .all-features-white-extra {

      transform: rotate(90deg);
      display: inherit;
      margin-left: 10px;
      width: 5px;
      /* Safari */
      -webkit-transform: rotate(90deg);

      /* Firefox */
      -moz-transform: rotate(90deg);

      /* IE */
      -ms-transform: rotate(90deg);

      /* Opera */
      -o-transform: rotate(90deg);


    }

    .all-features-white-noextra {

      transform: rotate(-90deg);
      display: inherit;
      margin-left: 10px;
      width: 5px;
      /* Safari */
      -webkit-transform: rotate(-90deg);

      /* Firefox */
      -moz-transform: rotate(-90deg);

      /* IE */
      -ms-transform: rotate(-90deg);

      /* Opera */
      -o-transform: rotate(-90deg);


    }
  }


  &.dark {
    .button.all-features-white {
      color: var(--blue-on-dark);
      border: 2px solid var(--blue-on-dark);
    }
  }


}
