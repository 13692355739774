@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.article-header {

  max-width: 686px;
  padding-bottom: 74px;
  padding-top: 148px;

  h1 {
    font-family: $h2-font;
    color: var(--text-font-color);
    font-size: $h2-font-size;
    font-weight: $font-weight-800;
    line-height: $h2-font-line-height;
    word-break: break-word;
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
    margin-left: auto;
    margin-right: auto;
  }

  p {
    max-width: 589px;
    //max-height: 52px;
    font-family: $p-font;
    color: var(--text-font-color);
    font-size: $p-font-size;
    line-height: $p-font-line-height;
    margin-left: auto;
    margin-right: auto;
  }

  .price-type-select {
    margin-top: 74px;
  }

  &.article-header-dark {

    padding-bottom: 98px;
    padding-top: 148px;

    h1 {
      font-family: $h2-font;
      //color: var(--text-font-color);
      color: $dark-font-color;
      font-size: $h2-font-size;
      font-weight: $font-weight-800;
      //line-height: $h2-font-line-height;
    }

    p {
      max-width: 589px;
      //max-height: 52px;
      font-family: $p-font;
      //color: var(--text-font-color);
      color: $dark-font-color;
      font-size: $p-font-size;
      line-height: $p-font-line-height;
    }

  }

  & > .article-header-buttons {

    width: 100%;
    padding-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;

    & > .article-header-buttons-button,
    & > .hg-button.hg-button {
      margin: 0 2em 2em 0;
    }

    & > .article-header-secondary-button,
    & > .hg-button.hg-secondary-button {
      box-sizing: border-box;
      font-size: $button-font-size;
      font-family: $button-font;
      border-radius: 21px;
      //background-color: var(--button-white-bgcolor);
      color: var(--blue-on-dark);
      border: 2px solid var(--blue-on-dark);
      text-align: center;
      min-width: 156px;
      //height: 41px;
      text-decoration: none;
      padding: 8px 16px;
    }

    & > .article-header-primary-button,
    & > .hg-button.hg-primary-button {
      box-sizing: border-box;
      background-color: #1689d0 !important;
      min-width: 180px;
      height: 41px !important;
      text-align: center;
      color: #ffffff !important;
      font-size: 16px !important;
      font-family: "redhat-medium" !important;
      border-radius: 21px;
      border: none;
      text-decoration: none;
      padding: 8px 16px !important;
    }

  }

}
