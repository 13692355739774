@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.cart-details {

  box-sizing: border-box;
  padding: 0;
  margin: 0;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-items: center;
  align-content: stretch;
  justify-content: center;
  flex-wrap: wrap;

  & > .cart-details-item {

    box-sizing: border-box;
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-items: center;
    align-content: center;
    justify-content: flex-end;
    flex-wrap: wrap;

    & > .cart-details-item-field {

      box-sizing: border-box;
      margin: 0;
      padding: 0 1em 1em 0;

      &.cart-details-item-title {
        flex-grow: 1;
      }

      &.cart-details-item-amount {
        text-align: right;
        flex-shrink: 0;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-items: center;
        align-content: center;
        justify-content: center;
        flex-wrap: wrap;

        & > .cart-details-remove-button {
          box-sizing: border-box;
          line-height: 0;
          border-radius: 0;
          padding: 0 0.5em !important;
          margin: 0 !important;
          border: 0;
          outline: 0;
          background-color: transparent !important;
          cursor: pointer;

          & > svg {
            width: 1em;
            height: auto;
          }
        }

      }

      &.cart-details-item-price {
        text-align: right;
        flex-shrink: 0;
      }

    }

    &.cart-details-totals {
      border-top: 1px solid;

      & > .cart-details-item-price {
        text-align: right;
      }

    }

  }

}
