@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.product-article {

  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  max-width: 1920px;
  padding-bottom: 9.8%;
  padding-top: 8.7%;

  & > .product-article-image {
    margin-left: 8%;
    max-width: 629px;
    max-height: 400px;
    border-radius: 42px;
  }

  & > .product-article-text {

    max-width: 100vw;
    width: 67.2%;
    margin-left: 6%;
    margin-right: 13.5%;

    h2 {
      font-family: $h2-font;
      color: var(--text-font-color);
      font-size: $h2-font-size;
      font-weight: $font-weight-800;
      line-height: $h2-font-line-height;
      word-break: break-word;
      overflow-wrap: break-word;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      hyphens: auto;
    }

    p {
      font-family: $p-font;
      color: var(--text-font-color);
      font-size: $p-font-size;
      line-height: $p-font-line-height;
    }

    & > .product-article-buttons {

      width: 100%;
      padding-top: 70px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-items: center;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;

      & > .product-article-buttons-button,
      & > .hg-button.hg-button {
        margin: 0 2em 2em 0;
      }

      & > .product-article-buy-button,
      & > .hg-button.buy-button {
        box-sizing: border-box;
        background-color: $blue !important;
        min-width: 180px;
        height: 41px !important;
        text-align: center;
        color: $white !important;
        font-size: $button-font-size !important;
        font-family: $button-font !important;
        border-radius: 21px;
        border: none;
        text-decoration: none;
        padding: 8px 16px !important;
      }

      & > .product-article-primary-button,
      & > .hg-button.hg-primary-button {
        box-sizing: border-box;
        background-color: $blue;
        text-align: center;
        color: $white;
        font-size: $button-font-size;
        font-family: $button-font;
        border-radius: 21px;
        border: none;
        margin-right: 24px;
        min-width: 156px;
        height: 41px;
        text-decoration: none;
        padding: 8px 46px;
      }

      & > .product-article-secondary-button,
      & > .hg-button.hg-secondary-button {
        box-sizing: border-box;
        font-size: $button-font-size;
        font-family: $button-font;
        border-radius: 21px;
        background-color: var(--button-white-bgcolor);
        color: var(--text-font-color);
        border: 2px solid var(--text-font-color);
        text-align: center;
        min-width: 156px;
        height: 41px;
        text-decoration: none;
        padding: 8px 16px;
      }

    }

  }

  // IPads (portraid and landscape) 768-1024 1024-1223 lisätty myös tähän
  @media only screen and (min-width: 768px) and (max-width: 1223px) {

    flex-wrap: wrap;
    justify-content: center;

    & > .product-article-image {
      margin-left: 0;
    }

    & > .product-article-text {
      max-width: 600px;
      padding: 2em;
      margin-left: 0;
      margin-right: 0;
    }

  }

  // mobiili
  @media only screen and (max-width: 767px) {

    flex-wrap: wrap;
    justify-content: center;

    & > .product-article-image {
      margin-left: 0;
      width: 90%;
    }

    & > .product-article-text {
      max-width: 629px;
      width: 70%;
      padding: 2em;
      margin-left: 6%;
      margin-right: 6%;
    }

  }

}
