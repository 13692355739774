@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.payment-option-list {

  box-sizing: border-box;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: nowrap;
  background-color: $white;
  border-radius: 0 7px 7px 0;

  & > .payment-option-list-image {

    background-color: #ec008c;
    flex-grow: 1;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    width: 100px;
    min-width: 100px;

    & > img {
      height: 100px;
      width: 100px;
    }

  }

  & > .payment-option-list-logos {

    min-width: 210px;
    flex-grow: 1;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;

    & > .payment-option-list-logo {
      box-sizing: border-box;
      padding: 0;
      margin: 0.5em;
    }

  }

  @media only screen and (max-width: 400px) {
    & {
      flex-wrap: wrap;
      border-radius: 0 0 7px 7px;

      & > .payment-option-list-image {

      }

      & > .payment-option-list-logos {

      }

    }
  }

}
