.virtual-server-extra-product-list-view {
  padding: 0 0 90px 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;

  & > h3 {
    margin-top: 4em;
    margin-bottom: 4em;
    padding: 0 2em;
    text-align: center;
  }

  & > .card-list {
    padding-top: 0;
  }

}

