@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.cart-article {

  box-sizing: border-box;
  padding: 1em 2em;
  margin: 0;
  font-size: $nav-font-size;
  font-family: $nav-font-family;

  & > .cart-article-items {

  }

  & > .cart-article-buttons {

    box-sizing: border-box;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    align-content: center;
    justify-content: flex-end;
    flex-wrap: wrap;

    & > .buy-button {

    }

  }

}
