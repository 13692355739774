@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.newsletter-view {
  transition: $transition_main;
  background-color: var(--background);
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;

  article.newsletter-view {
    max-width: 1920px;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    text-align: center;

    .newsletter-view-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
    }
  }

  //.newsletter-view-blue {
  //  text-align: center;
  //  width: 156px;
  //  height: 41px;
  //  margin-bottom: 97px;
  //  margin-top: 66px;
  //}

  //.newsletter-view-white-button {
  //  background-color: var(--newsletter-bgcolor) !important;
  //  color: var(--text-font-color) !important;
  //  border: 2px solid var(--text-font-color) !important;
  //  text-align: center;
  //  width: 164px;
  //  height: 41px;
  //  margin-bottom: 31px;
  //}

}
