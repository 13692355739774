@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.my-dashboard-menu {

  box-sizing: border-box;
  padding: 1em;
  margin: 0;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-items: stretch;
  align-content: stretch;
  justify-content: stretch;
  flex-wrap: wrap;
  background-color: #ffffff;
  border-radius: 12px;

  & > .my-dashboard-menu-section {

    box-sizing: border-box;
    margin: 0;
    padding: 0.5em 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-items: stretch;
    align-content: stretch;
    justify-content: stretch;
    flex-wrap: wrap;
    background-color: #ffffff;

    border-bottom: 1px solid #999;
    border-radius: 0;

    & > .my-dashboard-menu-link {
      box-sizing: border-box;
      margin: 0;
      flex: 1 0 auto;
      text-decoration: none;
      padding: 0.4em 0.6em;
      color: $nav-font-color;

      &.active {
        color: $nav-font-color-active;
      }

    }

    &:last-child {
      border-bottom: 0;
    }

  }

}
