@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.app-header {

  box-sizing: border-box;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
  max-width: 100vw;
  //max-height: 100vh;

  transition: $transition_main;
  //max-width: 1920px;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap-reverse; //omalla taitolla
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;

  & > .app-header-nav {

    //max-width: 1920px; //1468+37+117+36 = 1658
    overflow: hidden;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    display: flex;
    flex-flow: row;
    flex-grow: 3;
    text-align: center;
    align-content: center;

    a.app-header-logo {
      max-width: 190px;
      min-width: 117px;
      display: block;
      flex-shrink: 0;
      padding-top: 0;
      margin: 0;
      box-sizing: border-box;
      line-height: 0;
      flex-grow: 1;

      & > svg {
        margin-top: 0;
        margin-left: 0;
        width: 117px;
        height: 36px;
        fill: var(--header-logo);
      }

      & > svg:hover {
        fill: $blue;
      }

    }

    & > nav.app-header-menu-normal {
      box-sizing: border-box;
      margin-top: 0;
      margin-left: 36px;
      padding: 0;
      //max-width: 1920px; //app-header-nav - 36px
      width: 100%;
      height: 82px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
    }

    & > .app-header-actions-container {

      border-radius: 0 0 0 12px;
      max-width: 100%;
      padding: 12px 12px 12px 24px;
      margin: 0;
      flex-shrink: 0;
      flex-direction: row;
      align-items: center;
      align-content: center;
      justify-items: center;
      justify-content: flex-end;
      display: none;

      & > .action-field {
        padding: 0;
        margin: 0 0.5em 0 1em;
      }

      .action-button-icon {
        cursor: pointer;
        background-color: transparent;
        outline: 0;
        border: 0;
        box-sizing: border-box;
        padding: 0;
        margin: 0.25em 2em 0.25em 0;

        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-items: center;
        justify-content: center;

        & > svg {
          box-sizing: border-box;
          border: 0;
          outline: 0;
          display: inline-block;
          height: 2em;
          width: auto;
          margin: 0;
          padding: 0;
        }
      }

      & > .mobile-menu-toggle-container {
        display: none;
        width: 100%;
        max-width: 100vw;
        overflow: hidden;
        flex-grow: 1;
      }

    }

    & > .app-header-mobile-menu {
      & > .app-header-actions-container {
        .mobile-menu-toggle-container {
          display: none;
          width: 100%;
          max-width: 100vw;
          overflow: hidden;
          flex-grow: 1;
        }
      }
    }

    @media only screen and (max-width: 1223px) {

      & > nav.app-header-menu-normal,
      nav.app-header-menu-darker {
        display: none;
      }

      & > .app-header-actions-container {
        display: flex;
        padding: 0;
        border-radius: 0;
        align-items: center;
        width: 100%;
        justify-content: flex-start; //flex-end jos loppuun

        .mobile-menu-toggle-container {

          display: flex;
          width: 100%;
          max-width: 100vw;
          overflow: hidden;

          & > .app-header-logo {

            margin-top: 0;
            margin-left: 10px;
            flex-grow: 1;

            & > svg {
              margin-top: 23px;
              margin-left: 0;
              width: 117px;
              height: 23px;
            }

          }

          & > .container--toggle {
            flex-grow: 1;
          }

        }
      }

    }

  }

  & > .app-header-cart-container {
    box-sizing: border-box;
    padding: 0;
    margin: 4em 0;
    position: fixed;
    top: 0;
    right: 0;
    width: 400px;
    min-width: 200px;
    max-width: 100vw;
    background-color: #fff;
    overflow: auto;
    border-radius: 12px;
  }

  &.enable-fixed {
    position: fixed;
    top: 0;
    background-color: #ffffff;
  }

  .app-header-nav-menu {

    max-width: 1920px; //1468+37+117+36 = 1658
    overflow: hidden;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    display: flex;
    flex-flow: row;
    flex-grow: 3; //väliä vähän
    text-align: center;
    align-content: center;
    justify-content: space-evenly;
    transition: all 350ms ease-in-out;
    flex-wrap: wrap;

    & > a,
    & > a:visited,
    & > a:active,
    & > a:hover {
      color: var(--nav-font-color);
      text-decoration: none;
      // margin: 0;
      font-size: $nav-font-size;
      font-family: $nav-font-family;
      margin: 0.5em;
    }

    & > a:hover {
      color: $nav-font-color-active;
    }

    a.activated {
      color: $blue;
      text-decoration: none;
      transition: all 250ms ease-in-out;
    }

  }

  div.container--toggle {

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    //flex-grow: 1;
    margin-right: 20px;

    div.phone-button {
      background: $blue 0% 0% no-repeat padding-box;
      border-radius: 14px;
      opacity: 1;
      width: 108px;
      height: 27px;
      align-items: center; //keskittää vertikaalisesti
      justify-content: center; //keskittää horisontaalisesti

      display: flex;
      margin-right: 16px;

      > p {
        font-size: $nav-font-size;
        font-family: $nav-font-family;
        color: $white;
      }

      > a {
        font-size: $nav-font-size;
        font-family: $nav-font-family;
        color: $white;
        text-decoration: none;
      }
    }

    .cart-button {

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-items: center;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;

      box-sizing: border-box;
      padding: 0;
      margin: 0;
      outline: 0;
      border: 0;
      width: 1.75em;
      height: 1.75em;

      & > svg {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        outline: 0;
        border: 0;
        height: 1.2em;
        width: auto;
        fill: #fff;
      }

    }

    .login-button {

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-items: center;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;

      box-sizing: border-box;
      padding: 0;
      margin: 0 1em 0 0;
      outline: 0;
      border: 0;
      width: 1.75em;
      height: 1.75em;

      & > svg {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        outline: 0;
        border: 0;
        height: 1.2em;
        width: auto;
        fill: #fff;
      }

    }

    //dark/light mode
    input.toggle--checkbox {
      display: none;
      width: 40px;
      height: 19px;
    }

    .toggle--checkbox:checked + .toggle--label {
      background: var(--dark-color);
      border-color: var(--dark-border);
    }

    .toggle--label {
      //margin-top: 4px;
      width: 40px;
      height: 19px;
      background: var(--light-color);
      border-radius: 100px;
      border: 1px solid var(--light-border);
      display: flex;
      position: relative;
      text-align: center;
      justify-content: space-between;
      padding: 0 1px;
      align-content: center;
      //transition: all 350ms ease-in;

      > .toggle--label-background {
        //padding-left: 5px;
        line-height: 1.4em;
        font-size: 1em;
        padding: 0 1px 0 1px;
        color: #ffffff;
      }

      > .toggle--label-moon-background {
        //text-align: center;
        //padding-left: 5px;
        //padding-top: 1px;
        opacity: 0;
        font-size: 1em;
        line-height: 1.4em;
        padding: 0 1px 0 1px;
      }
    }

    .toggle--label:before {
      transition: all 350ms ease-in;
      content: "";
      width: 14px;
      height: 14px;
      top: 2.5px;
      left: 2.5px;

      position: absolute;

      border-radius: 50%;
      background-color: var(--light-white-background);
      //background-color: cornflowerblue;
    }

    .toggle--checkbox + .toggle--label {
      > .toggle--label-moon-background {
        opacity: 1;
        color: #FFFFFF;
        visibility: visible;
      }

      > .toggle--label-background {
        transition-delay: 350ms;
        visibility: hidden;
      }
    }

    .toggle--checkbox:checked + .toggle--label {
      background-color: var(--dark-color);
      border-color: var(--dark-border);
      color: var(--white);

      //background-color: chartreuse;
      > .toggle--label-background {
        transition-delay: 350ms;
        visibility: visible;
      }

      > .toggle--label-moon-background {
        transition-delay: 350ms;
        visibility: hidden;
      }
    }

    .toggle--checkbox:checked + .toggle--label:before {
      background-color: var(--white);
      //background-color: cornflowerblue;
      background-color: white;

      transform: translate(23px);
      -webkit-transform: translate(23px);
      -ms-transform: translate(23px);
    }

    .toggle--checkbox:checked + .toggle--label:after {
      transition-delay: 350ms;
      opacity: 1;
    }

  }

  @media only screen and (max-width: 1223px) {
    & > .app-header-nav {

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;

      & > .app-header-actions-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border-radius: 0 0 0 12px;

        //mobile toggle menu
        .app-header-mobile-menu {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          margin-top: 24px;
          margin-left: 24px;
          flex-grow: 1;
          text-align: left;
          padding: 1em;

          > a.nav-link {
            width: 100%;
            padding: 0.5em;
          }

          & > a,
          & > a:visited,
          & > a:active,
          & > a:hover {
            color: var(--nav-font-color);
            //color: black;
            margin: 0;
            text-decoration: none;
            font-size: $nav-font-size;
            font-family: $nav-font-family;
          }

          & > a:hover {
            color: $nav-font-color-active;
          }

          a.activated {
            color: $blue;
            text-decoration: none;
            transition: all 250ms ease-in-out;
          }
        }
      }

    }
  }

}
