.my-select-any-client-article {

  flex-direction: row;
  display: flex;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;

  & > .hg-field {
    & > .hg-field-input {
      width: 5em;
    }
  }
}