@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.my-client-view {

  box-sizing: border-box;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;

  & > h3 {
    text-align: center;
  }

  & > p {
    text-align: center;
  }

  & > .my-client-view-details {
    width: 750px;

    & > .my-client-view-details-error-message {
      text-align: center;
    }

  }

  & > .my-client-view-fieldset {

    width: 450px;
    max-width: 90vw;
    box-sizing: border-box;
    padding: 0;
    margin: 0 0 2em 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;

    & > .my-client-view-radio-label {
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      flex: 1 0 auto;
      line-height: 2em;
    }

  }

  & > .my-client-view-buttons {

    box-sizing: border-box;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;

  }

}
