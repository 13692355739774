@import "../../../styles/index";

.hg-select-template {

  box-sizing   : border-box;
  padding      : 0;
  margin       : $field-margin;

  & > .hg-select-template-label {

    padding           : 0;
    margin            : 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;

    & > .hg-select-template-label-text {

      flex-shrink: 1;
      padding: 0 1em 0 0.5em;
      box-sizing: border-box;
      min-width: $field-label-min-width;
      text-align   : right;

    }

    & > .hg-select-template-input {
      box-sizing: border-box;
      padding: 1em 0.75em;
      flex-grow: 1;
      border: 0;
      font-size: 1.25em;
      border-radius: 0.25em;
      max-width: 100%;
      min-width: 0;
    }

  }

  & > .hg-popup {

    top: -5px;
    box-sizing: border-box;

    & > .hg-popup-window {

      border-radius  : 0.5em;
      right          : 0;
      left           : 0;
      box-sizing     : border-box;
      min-width      : 15em;

      & > .hg-select-template-dropdown {

        //background-color : #00000009;
        border           : 1px solid #00000022;
        border-radius    : 0.5em;
        box-sizing       : border-box;
        padding          : 0.25em 0;
        margin           : 0;

        display          : flex;
        flex-direction   : column;

        & > .hg-select-template-dropdown-item {

          box-sizing       : border-box;
          //background-color : #ffffff;
          padding          : 0.5em;
          margin           : 0.25em 0.5em;

          //&.hg-select-field-dropdown-item-current {
          //  background-color : #ffffffff;
          //}

        }

      }

    }

  }

  &.hg-field-state-invalid {
    border            : $field-border-invalid;
  }

}
