@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.my-order-list-view {

  box-sizing: border-box;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;

  & > h3 {
    text-align: center;
  }

  & > p {
    text-align: center;
  }

  & > .my-order-view-details {

  }

}
