@import "src/fi/sendanor/frontend/styles/sendanor-variables";
 /*.mobile-menu-toggle-container {
    display: none;
    flex-shrink: 0;*/

    .mobile-menu-toggle {
      margin: 0;
      padding: 12px 12px 12px 24px;
      background: none;
      border: 0;
      outline: 0;

      display: flex;
      flex-direction: row;

      justify-items: center;
      align-content: center;
      justify-content: center;

      svg {
        height: 1.5em;
        width: auto;
      }

      //hamburger X menu
      #overlay-button {
        cursor: pointer;
        user-select: none;
        span {
          height: 4px;
          width: 35px;
          border-radius: 2px;
          //margin-left: 20px;
          margin-top: 20px;
          margin-bottom: 20px;
          background: var(--nav-font-color);
          position: relative;
          display: block;
          transition: all 0.2s ease-in-out;
          &:before {
            top: -10px;
            visibility: visible;
          }
          &:after {
            top: 10px;
          }
          &:before,
          &:after {
            height: 4px;
            width: 35px;
            border-radius: 2px;
            background: var(--nav-font-color);
            position: absolute;
            left: 0px;
            content: "";
            transition: all 0.2s ease-in-out;
          }
        }
        &:hover span,
        &:hover span:before,
        &:hover span:after {
          background: $nav-font-color-active;
        }
      }

      input[type="checkbox"] {
        display: none;
      }

      input[type="checkbox"]:checked ~ #overlay {
        visibility: visible;
      }

      input[type="checkbox"]:checked ~ #overlay-button {
        &:hover span,
        span {
          background: transparent;
        }
        span {
          &:before {
            transform: rotate(45deg) translate(7px, 7px);
            opacity: 1;
          }
          &:after {
            transform: rotate(-45deg) translate(7px, -7px);
          }
        }
      }
      //hamburger x menu päättyy
    }

