@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.domain-sale-product-view {

  width: 100%;
  transition: $transition_main;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;

  & > .domain-sale-product-view-article {
    background-color: var(--light-blue);
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;

    &.domain-sale-product-view-odd-article {
      background-color: var(--background);
    }

  }

}
