@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.virtual-server-product-view {

  color: $dark-font-color;
  background-color: var(--background-darker);
  font-family: $table-title-2-font;
  font-size: $table-title-2-size;
  line-height: $table-title-2-line-height;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0 79.5px 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;

  & > .virtual-server-product-view-article {
    overflow: auto;
    width: 1920px;
    max-width: 100vw;
    padding: 0;
    margin: 0 auto;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;

    & > .virtual-server-product-view-article-header {
      h1 {
        padding: 0 0.5em;
      }
      p {
        padding: 0 0.5em;
      }
    }

    .virtual-server-product-view-primary-button,
    .hg-button.hg-primary-button {
      box-sizing: border-box;
      background-color: #1689d0 !important;
      min-width: 180px;
      height: 41px !important;
      text-align: center;
      color: #ffffff !important;
      font-size: 16px !important;
      font-family: "redhat-medium" !important;
      border-radius: 21px;
      border: none;
      text-decoration: none;
      padding: 8px 16px !important;
    }

    .virtual-server-product-view-secondary-button,
    .hg-button.hg-secondary-button {
      box-sizing: border-box;
      font-size: $button-font-size;
      font-family: $button-font;
      border-radius: 21px;
      //background-color: var(--button-white-bgcolor);
      color: var(--blue-on-dark);
      border: 2px solid var(--blue-on-dark);
      text-align: center;
      min-width: 156px;
      //height: 41px;
      text-decoration: none;
      padding: 8px 16px;
    }

  }

  & > h3 {
    margin-top: 4em;
  }

}
