.hg-table-header-column {

  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-align: left;

  & > .hg-table-header-column-content {
    padding: 0.25em 0.75em;
    text-align: left;
    font-weight: bold;
  }

}
