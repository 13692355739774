@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.product-table-item-data {

  padding-left: 5px;
  border-bottom: 1px solid var(--table-border);
  border-right: 0.5px solid var(--table-border-light);
  max-width: 329px;
  width: 17.14%;
  height: 33px;
  text-align: left;
  //padding-top: 39px;
  //padding-bottom: 39px;
  font-family: $table-title-2-font;
  font-size: $table-title-2-size;
  line-height: $table-title-2-line-height;

  &.product-table-item-data-main-title-row {
    //padding-top: 39px;
    //padding-bottom: 39px;
    border-right: none;

    & > .product-table-item-data-header {
      padding-top: 39px;
      padding-bottom: 16px;
      border: none;
    }

  }

  & > .product-table-item-data-header {

    color: var(--text-font-color);
    border-left: 0.5px solid var(--table-border-light);
    border-right: 0.5px solid var(--table-border-light);

    & > .product-table-item-data-header-content {

      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      max-width: 270px;

      & > .product-table-item-data-header-content-left {
        max-width: 164px;
        min-width: 109px;
      }

      & > .product-table-item-data-header-content-right {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        max-width: 151px;
        margin-right: 0;
        font-family: $table-title-3-font;

        & > div {
          display: flex;
          flex-wrap: nowrap;
          flex-direction: row;
          padding-left: 21px;
        }

      }

    }

  }

  & > .product-table-item-data-product {

    color: var(--text-font-color);
    padding-left: 15px;
    border-bottom: 1px solid var(--table-border);
    border-right: 0.5px solid var(--table-border-light);
    //width: 329px;
    min-height: 33px;
    text-align: left;
    //padding-top: 39px;
    //padding-bottom: 39px;
    font-family: $table-title-2-font;
    font-size: $table-title-2-size;
    line-height: $table-title-2-line-height;

    &:nth-last-child(1) {
      border-right: none;
    }

  }

  &.product-table-item-data-dark {

    color: $dark-font-color;

    & > .product-table-item-data-header {
      color: $dark-font-color;
    }

    & > .product-table-item-data-product {
      color: $dark-font-color;
    }

  }

  @media only screen and (max-width: 1223px) {

    & > .product-table-item-data-header {
      & > .product-table-item-data-header-content {

        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        max-width: 140px;
        align-items: flex-start;

        & > .product-table-item-data-header-content-left {
          width: 100%;
          //height: 3em;
        }

        & > .product-table-item-data-header-content-right {

          width: 100%;
          margin-left: 0;

          & > div {
            padding-left: 0;
          }

        }

      }
    }

    & > .product-table-item-data-product {
      //padding-top: 3em;
      padding-bottom: 0;
    }

  }

}
