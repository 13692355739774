@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.email-extra-product-list-view {

  background-color: var(--background);
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  padding-bottom: 79.5px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;

  & > .email-extra-product-list-view-article {
    max-width: 1920px;
    padding: 0;
    margin: 0 auto;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

}
