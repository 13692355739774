@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.cart-view {

  & > .cart-view-body {

    box-sizing: border-box;
    padding: 4em 6em;
    margin: 0;

  }

}
