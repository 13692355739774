@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.my-select-client-article {

  box-sizing: border-box;
  margin: 0;
  padding-top: 62.13px;

  & > .my-select-client-article-form {

    box-sizing: border-box;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;

    & > h3 {
      text-align: center;
    }

    & > p {
      text-align: center;
    }

    & > .my-select-client-article-fieldset {

      //width: 450px;
      max-width: 90vw;
      box-sizing: border-box;
      padding: 0;
      margin: 0 0 2em 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-items: center;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;

      & > .my-select-client-article-radio-label {
        padding: 1em 2em;
        margin: 0 5px 5px 0;
        background-color: $white;
        border-radius: 12px;
        box-sizing: border-box;
        line-height: 2em;
        min-width: 18em;
        max-width: 95vw;
      }

    }

    & > .my-select-client-article-new-client-buttons {

      box-sizing: border-box;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-items: center;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;

      & > .button {
        margin: 0 0.5em;
      }

    }

    &.my-select-client-article-select-client-form {
      margin-top: 3em;
      flex-direction: row;
      display: flex;
      align-items: center;
      justify-items: center;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;

      & > .hg-field {
        & > .hg-field-input {
          width: 5em;
        }
      }
    }

  }

}
