@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.email-footer-view {
  transition: $transition_main;
  background-color: var(--light-blue);
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}
