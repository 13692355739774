html {
    height: 100%;
  }
  
  body {
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  
  }
  
  #root {
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  