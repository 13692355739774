@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.button {

  background-color: $blue;
  text-align: center;
  color: $white;
  font-size: $button-font-size;
  font-family: $button-font;
  line-height: $button-font-line-height;
  border-radius: 21px;
  border: none;

  &.button-link {
    text-decoration: none;
    line-height: 41px;
    padding: 8px 16px;
  }

}

