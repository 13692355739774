@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.cookie-consent-article {

  max-width: 1024px;
  width: 87%;

  margin: 0 auto;
  box-sizing: border-box;
  padding-top: 31px;
  padding-bottom: 31px;

  & > .cookie-consent-article-form {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;

    & > .cookie-consent-article-section {

      flex: 1;
      margin: 0 12px;
      text-align: center;

      & > .hg-button.cookie-consent-article-accept-all {
        min-width: 15em;
        text-align: center;
        margin: 0 auto;
      }

      & > .hg-button.cookie-consent-article-accept-mandatory {
        min-width: 15em;
        text-align: center;
        margin: 20px auto 0;
      }

      & > .hg-button.cookie-consent-article-remove-consent {
        min-width: 15em;
        text-align: center;
        margin: 20px auto 0;
      }

      & > .hg-button.cookie-consent-article-settings {
        min-width: 15em;
        text-align: center;
        margin: 20px auto 0;
      }

    }

  }

}
