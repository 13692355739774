@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.my-request-sms-article {

  box-sizing: border-box;
  margin: 0;
  padding-top: 62.13px;
  padding-left: 57.53px;

  & > .my-request-sms-article-form {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;

    & > h3 {
      text-align: center;
    }

    & > p {
      text-align: center;
    }

    & > .hg-button {
      margin-bottom: 2em;
      width: auto;
    }

  }

  & > .my-request-sms-article-error-message {
    padding-top: 62.13px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
  }

}
