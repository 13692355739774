@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.card-list {

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  box-sizing: border-box;
  border: none;
  background-color: transparent;
  margin-top: 0;

  //padding-left: 18.75%;
  //padding-right: 18.75%;
  padding-top: 90.36px;
  justify-content: center;

  & > .card-list-children {
    display: flex;
    flex-direction: row;
    flex: 1 1 100%;
    align-content: center;
    justify-content: center;
  }

  & > .card-list-no-results {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    p {
      max-width: 589px;
      max-height: 52px;
      font-family: $p-font;
      color: var(--text-font-color);
      font-size: $p-font-size;
      line-height: $p-font-line-height;
    }

  }

}

