.my-inventory-item-view {

  box-sizing: border-box;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;

  & > h3 {
    text-align: center;
  }

  & > p {
    text-align: center;
  }

  & > .my-inventory-item-view-details {

    position: relative;

    & > .my-inventory-item-view-details-error-message {
      font-weight: 800;
      text-align: center;
    }

    & > .my-inventory-item-view-details-loader {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

  }

}
