@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.price-type-select {

  box-sizing: border-box;
  margin: 0 2em 2em 2em;
  padding: 0;

  & > .price-type-select-select {
    padding: 0.5em 1em;
    width: 100%;
    color: var(--text-font-color);
    background-color: var(--background);
    border: 1px solid #1b2d3b;
    border-radius: 8px;


    & > .price-type-select-select-option {
      width: 100%;
    }

  }

  &.price-type-select-dark {
    & > .price-type-select-select {
      color: $dark-font-color;
      background-color: var(--background-darker);
      border: 1px solid #ffffff;
    }
  }

}
