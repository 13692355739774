@import "../../../../styles/sendanor-variables";

.web-hotel-footer-view {

  transition: $transition_main;
  //background-color: #e7edf2;
  background-color: var(--light-blue);
  width: 100%;
  display: flex;

  align-items: center;
  align-content: center;
  justify-content: center;

  & > .web-hotel-footer-view-article {
    background-color: var(--light-blue);
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

}
