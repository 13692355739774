@import "src/fi/sendanor/frontend/styles/sendanor-variables";

.product-table-item-header {

  color: var(--text-font-color);
  min-width: 150px;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid var(--table-border);
  border-right: 1px solid var(--table-border);
  max-width: 329px;
  height: 33px;
  text-align: left;
  vertical-align: top;
  font-family: $table-title-3-font;
  font-size: $table-title-2-size;
  line-height: $table-title-2-line-height;
  padding-bottom: 31px;

  & > .product-table-item-header-title {
    font-family: $table-title-font;
    font-size: $table-title-size;
    line-height: $table-title-line-height;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    word-wrap: break-word;
    overflow-wrap: break-word;
    padding: 0;
    margin: 0;
  }

  & > .product-table-item-header-price {

    padding: 0;
    margin: 0.75rem 0;

    & > .product-table-item-header-price-description {

    }

    & > .product-table-item-header-price-description-discount {
      color: #009d00;
    }

    & > .product-table-item-header-price-description-original {
      text-decoration: line-through;
    }

  }

  &.product-table-item-header-dark {

    color: $dark-font-color;

    & > .product-table-item-header-price {

      & > .product-table-item-header-price-description-discount {
        color: #87d487;
      }

      & > .product-table-item-header-select-price {
        color: $dark-font-color;
      }
    }

  }

  @media only screen and (max-width: 1223px) {
    max-width: 140px;
  }

}
